import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthService} from '../services/auth.service';


@Injectable({
   providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {

   constructor(public auth: AuthService) {
   }

   intercept(
      request: HttpRequest<any>,
      next: HttpHandler
   ): Observable<HttpEvent<any>> {
      request = request.clone({
         setHeaders: {            'X-ADMIN': '1'        }
      });
      if (this.auth.isUserAuthenticated()) {
         request = request.clone({
            setHeaders: {
               'X-ADMIN': '1',
               Authorization: `Bearer ${this.auth.user.api_token}`,
            }
         });
      }
      return next.handle(request);
   }
}
