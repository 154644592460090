import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {environment} from '../../../environments/environment';
import swal from 'sweetalert2';
import {finalize} from 'rxjs/operators';
import {AuthService} from '../../services/auth.service';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../../services/api.service';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {NgbActiveModal, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {
   ILaravelDataTableConfig
} from '../../../../projects/xs-laravel-data-table/src/interfaces/ILaravelDataTableConfig';

export interface IAdManagerConfig {
   isModal: boolean;
   adType: string;
   parent: number;
   title?: string;
}

@Component({
   selector: 'app-manage-ads',
   templateUrl: './manage-ads.component.html',
   styleUrls: ['./manage-ads.component.scss']
})
export class ManageAdsComponent implements OnInit {
   @ViewChild('addNew') addNewModal;
   @ViewChild('table') table;
   @Input() public inModal = false;
   @Input()
   public componentConfig: IAdManagerConfig;
   public selectedItem;
   public endpoint;
   public tableConfig: ILaravelDataTableConfig = {
      fields: [
         {name: 'Photo', key: 'image_url', type: 'image', width: 'min'},
         {name: 'Link Url', key: 'url', type: 'text',},
         {name: 'Sort', key: 'sort_order', type: 'sort', width: 'min'},
      ],
      actions: [
         {
            name: 'Edit',
            type: 'primary',
            fn: (dataset, index) => {
               this.selectedItem = dataset[index];
               this.form.patchValue(this.selectedItem);
               this.modalRef = this.modal.open(this.addNewModal, {size: 'lg'});
            }
         },
         {
            name: 'Delete',
            type: 'danger',
            fn: (dataset, index) => {
               swal.fire({
                  title: 'Delete :' + dataset[index].url + '?',
                  icon: 'question',
                  showCancelButton: true,
                  cancelButtonText: 'No',
                  confirmButtonText: 'Yes',
               }).then(res => {
                  if (res.value) {
                     this.loader.start();
                     this.api.post('admin/advertisement/delete', {id: dataset[index].id})
                        .pipe(finalize(() => {
                           this.loader.stop();
                        }))
                        .subscribe((res: any) => {
                           dataset.splice(index, 1);
                        });
                  }
               });
            }
         }
      ]
   };
   public form: UntypedFormGroup;
   public modalRef: NgbModalRef;

   constructor(public toastr: ToastrService,
               public api: ApiService,
               public auth: AuthService,
               public router: Router,
               public modal: NgbModal,
               public activeModal: NgbActiveModal,
               public loader: NgxUiLoaderService) {
      this.form = new UntypedFormGroup({
         id: new UntypedFormControl(),
         field_id: new UntypedFormControl('', Validators.required),
         advertise_type: new UntypedFormControl('', Validators.required),
         url: new UntypedFormControl('', Validators.required),
         image: new UntypedFormControl('', Validators.required),
         status: new UntypedFormControl('', Validators.required),
         sort_order: new UntypedFormControl('')
      });
   }

   ngOnInit() {
      this.endpoint = environment.endpoint + 'admin/advertisement/list' + '?adType=' + this.componentConfig.adType;
      if (this.componentConfig.parent) {
         // we get only ads for this
         this.endpoint += '&field_id=' + this.componentConfig.parent;
      }
   }

   public setConfig(config) {
      this.componentConfig = config;
   }

   add(addNew: TemplateRef<any>) {
      this.selectedItem = null;
      this.form.reset({
         field_id: this.componentConfig.parent,
         advertise_type: this.componentConfig.adType,
         status: 'publish'
      });
      this.modalRef = this.modal.open(addNew, {size: 'lg'});
      //console.log(this.modalRef);

   }

   save() {
      this.loader.start();
      this.api.post('admin/advertisement/save', this.form.value)
         .pipe(finalize(() => {
            this.loader.stop();
         }))
         .subscribe((res: any) => {
            this.modalRef.close(res.data);
            this.table.loadData();
         });
   }
}
