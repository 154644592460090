import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {animate, style, transition, trigger} from "@angular/animations";

@Component({
   selector: 'app-menu-item',
   standalone: true,
   imports: [CommonModule],
   templateUrl: './menu-item.component.html',
   styleUrls: ['./menu-item.component.scss'],
   animations: [
      trigger('slideToggle', [
         transition(':enter', [
            style({height: 0, opacity: 0}),
            animate('300ms ease-out', style({height: '*', opacity: 1}))
         ]),
         transition(':leave', [
            animate('300ms ease-in', style({height: 0, opacity: 0}))
         ])
      ])
   ]
})
export class MenuItemComponent {
   public collapsed = true;
   @Input() label;

   toggleMenu(): void {
      this.collapsed = !this.collapsed;
   }
}
