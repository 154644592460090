<div class="page-title d-flex justify-content-between align-items-center">
   <h3>Manage Student Enquiries</h3>
   <div *appPermissionCheck="'add_student_enquiries'">
      <a class="btn btn-primary btn-icon" routerLink="/dashboard/entry/enquiry-form"> Add New</a>
   </div>
</div>
<p-selectButton [(ngModel)]="enquiryType" (ngModelChange)="enquiryTypeChanged()" [options]="['Website','Walkins']"></p-selectButton>
<div class="tw-mt-8"></div>
<xs-laravel-data-table #table [endpoint]="endpoint" [config]="tableConfig">
   <ng-template lt-cell-template field="interested_in" let-idx="index" let-item="">
      <span class="tw-py-1 tw-mr-1 tw-px-2 tw-text-red-500 tw-text-sm tw-bg-red-50 tw-rounded" *ngFor="let p of item.interested_programs">{{ p.name }}</span>
   </ng-template>

   <ng-template lt-cell-template field="user_id" let-idx="index" let-item="">
      <ng-container *ngIf="item.user_id==null">WEBSITE</ng-container>
      <ng-container *ngIf="item.user_id!=null">{{ item?.user?.name }}</ng-container>
   </ng-template>
   <ng-template field="status" let-idx="index" lt-cell-template="" let-item="">
      <app-badge [text]="item.status"></app-badge>
   </ng-template>
   <ng-template field="name" let-idx="index" lt-cell-template="" let-item="">
      <div class="d-flex align-items-center">
         <app-cdn-image [src]="item.featured_image" class="mr-2"></app-cdn-image>
         {{ item.name }}
      </div>
   </ng-template>
   <ng-template field="actions" let-idx="index" lt-cell-template="" let-item="">
      <button type="button" (click)="download(item)" class="px-3 py-1 text-white rounded tw-bg-teal-700">Download</button>
   </ng-template>
</xs-laravel-data-table>

<p-dialog [modal]="true" [(visible)]="dialogVisible" header="Contact Form" *ngIf="selectedItem">
   <form [formGroup]="form">

      <div class="tw-flex tw-py-3 tw-border-b tw-justify-between">
         <div>Name</div>
         <div>{{ selectedItem.name }}</div>
      </div>
      <div class="tw-flex tw-py-3 tw-border-b tw-justify-between">
         <div>Email</div>
         <div>{{ selectedItem.email }}</div>
      </div>
      <div class="tw-flex tw-py-3 tw-border-b tw-justify-between">
         <div>Phone</div>
         <div>{{ selectedItem.phone }}</div>
      </div>
      <div class="tw-flex tw-py-3 tw-border-b tw-justify-between">
         <div>Interested In</div>
         <div>
            <span class="tw-py-1 tw-mr-1 tw-px-2 tw-text-red-500 tw-text-sm tw-bg-red-50 tw-rounded" *ngFor="let p of selectedItem.interested_programs">{{ p.name }}</span>
         </div>
      </div>
      <div class="tw-py-3 tw-border-b tw-justify-between">
         <label class="tw-font-bold tw-block tw-text-sm" for="">Message</label>
         {{ selectedItem.message }}
      </div>

      <div class="tw-flex tw-items-center tw-py-3 tw-border-b tw-justify-between">
         <div>Status</div>
         <div>
            <p-selectButton formControlName="status" [options]="[{'label':'Pending',value:'pending'},{'label':'Processed',value:'processed'}]"></p-selectButton>
         </div>
      </div>

      <div class="tw-mt-8">
         <label for="">Reply ( leave empty to if you dont wish to reply)</label>
         <textarea formControlName="reply" class="tw-w-full tw-p-4 tw-mt-2 tw-border" placeholder="Reply to user"></textarea>
      </div>
      <button (click)="save()" class="theme-btn">{{ form.value.id ? 'Update' : 'Save' }}</button>

   </form>

</p-dialog>

