<div class="row d-none">
   <div class="col-sm-12">
      <div class="section">Top Bar Contact</div>
   </div>
   <div class="col-sm-6">
      <div class="form-group">
         <label>Phone Number</label>
         <input type="text" class="form-control" [(ngModel)]="config.top_phone">
      </div>
   </div>
   <div class="col-sm-6">
      <div class="form-group">
         <label>Telegram Group Link</label>
         <input type="text" class="form-control" [(ngModel)]="config.telegram_link">
      </div>
   </div>
</div>

<div class="section">Mobile App Banners (Drag and drop to reorder)</div>
<app-multi-pic-uploader label="Upload Images (1920 x1080)" dir="home-banners" standAlone="true" (uploaded)="appBannerUploaded($event)"></app-multi-pic-uploader>
<div class="d-flex flex-wrap" cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="appBannerDrop($event)">
   <div class="mb-4 mr-2 position-relative" *ngFor="let b of config?.app_banners;let idx=index" cdkDrag>
      <app-cdn-image [src]="b" [width]="130" height="0"></app-cdn-image>
      <a class="position-absolute text-center" (click)="this.config.app_banners.splice(idx,1)" style="left: 0;bottom: -15px;background-color: red;color: #ffffff;width: 100%;">Remove</a>
   </div>
</div>


<div class="section">Banner Images (Drag and drop to reorder)</div>
<app-multi-pic-uploader label="Upload Images (1920 x1080)" dir="home-banners" standAlone="true" (uploaded)="bannerUploaded($event)"></app-multi-pic-uploader>
<div class="d-flex flex-wrap" cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="bannerDrop($event)">
   <div class="mb-4 mr-2 position-relative" *ngFor="let b of config?.banners;let idx=index;trackBy:identify" cdkDrag>
      <app-cdn-image [src]="b" [width]="130" height="0"></app-cdn-image>
      <a class="position-absolute text-center" (click)="this.config.banners.splice(idx,1)" style="left: 0;bottom: -15px;background-color: red;color: #ffffff;width: 100%;">Remove</a>
   </div>
</div>
<div class="section">Side Banner Images (Drag and drop to reorder)</div>
<app-multi-pic-uploader label="Upload Images" dir="home-banners" standAlone="true" (uploaded)="sideBannerUploaded($event)"></app-multi-pic-uploader>
<div class="d-flex flex-wrap" cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="sideBannerDrop($event)">
   <ng-container *ngIf="config?.banners?.length>0">
   <div class="mb-4 mr-2 position-relative" *ngFor="let b of config?.side_banners;let idx=index" cdkDrag>
      <app-cdn-image [src]="b" [width]="130" height="0"></app-cdn-image>
      <a class="position-absolute text-center" (click)="this.config?.side_banners.splice(idx,1)" style="left: 0;bottom: -15px;background-color: red;color: #ffffff;width: 100%;">Remove</a>
   </div>
   </ng-container>
</div>

<div class="row mt-4">
   <div class="col-sm-6">
      <label class="tw-font-bold">Slider Color</label>
      <div class="form-group">
         <input type="color" [(ngModel)]="config.slider_color" class="form-control">
      </div>
   </div>
   <div class="col-sm-6">
      <label class="tw-font-bold">Menu Color</label>
      <div class="form-group">
         <input type="color" [(ngModel)]="config.menu_color" class="form-control">
      </div>
   </div>
</div>


<div class="section mt-4">You are Looking For ( 4 items )</div>

<div class="row pt-4 pb-4">
   <div class="col-sm-6">

      <div class="alert alert-danger" *ngIf="config.looking_for && config.looking_for.length==0">No Items</div>
      <table class="table table-bordered ">
         <tr>
            <th class="min">ID</th>
            <th class="min">Icon</th>
            <th>Title</th>
            <th>Actions</th>
         </tr>
         <tbody cdkDropList (cdkDropListDropped)="sort($event,config.looking_for)">
         <tr *ngFor="let item of config.looking_for;let idx=index" cdkDrag>
            <td>{{idx + 1}}</td>
            <td><img [src]="photoService.exact(item.icon,70,70)" alt=""></td>
            <td>
               <div class="font-weight-bold">{{item.title}}</div>
               <small class="d-block">{{item.sub}}</small>
               <a [href]="item.link" target="_blank">{{item.link}}</a>
            </td>
            <td>
               <button class="btn btn-sm btn-secondary mr-2" (click)="edit(item,idx)">Edit</button>
               <button class="btn btn-sm btn-danger" (click)="this.config.looking_for.splice(idx,1)">Delete</button>
            </td>
         </tr>
         </tbody>
      </table>

   </div>
   <div class="col-sm-6">
      <form [formGroup]="lookingFor" (submit)="addItem()">
         <input type="hidden" formControlName="index">
         <div class="row">
            <div class="col-sm-8">
               <div class="form-group">
                  <label>Title <span>*</span></label>
                  <input type="text" class="form-control" formControlName="title">
               </div>
               <div class="form-group">
                  <label>Sub title <span>*</span></label>
                  <input type="text" class="form-control" formControlName="sub">
               </div>
               <div class="form-group">
                  <label>Url/Link <span>*</span></label>
                  <input type="text" class="form-control" formControlName="link">
               </div>
            </div>
            <div class="col-sm-4">
               <div class="form-group">
                  <label>Icon Upload <span>*</span></label>
                  <app-photo-uploader width="100" height="100" dir="looking-for" formControlName="icon"></app-photo-uploader>
               </div>
            </div>
         </div>

         <button [disabled]="lookingFor.invalid" class="btn btn-secondary"> {{lookingFor.value.index != null ? 'Update item' : 'Add Item'}}</button>
         <button class="btn btn-danger ml-2" *ngIf="lookingFor.value.index!=null " (click)="lookingFor.reset()"> Cancel Update</button>
      </form>
   </div>
</div>

<div class="section">Our Courses ( maximum 8 items )</div>
<div class="row pt-4 pb-4">
   <div class="col-sm-6">

      <div class="alert alert-danger" *ngIf="config.programs && config.programs.length==0">No Items</div>
      <table class="table table-sm table-striped table-bordered ">
         <tr>
            <th class="min">ID</th>
            <th class="min">Icon</th>
            <th>Title</th>
            <th>Actions</th>
         </tr>
         <tbody cdkDropList (cdkDropListDropped)="sort($event,config.programs)">
         <tr *ngFor="let item of config.programs;let idx=index" cdkDrag>
            <td class="text-center " style="vertical-align:middle;">{{idx + 1}}</td>
            <td><img [src]="photoService.exact(item.icon,70,70)" alt=""></td>
            <td>
               <div class="font-weight-bold">{{item.title}} - <small>{{item.sub}}</small></div>

               <a [href]="item.link" target="_blank">{{item.link}}</a>
            </td>

            <td>
               <button class="btn btn-sm btn-secondary mr-2" (click)="editProgram(item,idx)">Edit</button>
               <button class="btn btn-sm btn-danger" (click)="this.config.programs.splice(idx,1)">Delete</button>
            </td>
         </tr>
         </tbody>
      </table>

   </div>
   <div class="col-sm-6">
      <form [formGroup]="programForm" (submit)="addProgramItem()">
         <div class="row">
            <div class="col-sm-8">
               <div class="form-group">
                  <label>Title <span>*</span></label>
                  <input type="text" class="form-control" formControlName="title">
               </div>
               <div class="form-group">
                  <label>Sub title <span>*</span></label>
                  <input type="text" class="form-control" formControlName="sub">
               </div>
               <div class="form-group">
                  <label>Url/Link <span>*</span></label>
                  <input type="text" class="form-control" formControlName="link">
               </div>
            </div>
            <div class="col-sm-4">
               <div class="form-group">
                  <label>Icon Upload <span>*</span></label>
                  <app-photo-uploader width="500" height="500" dir="looking-for" formControlName="icon"></app-photo-uploader>
               </div>
            </div>
         </div>

         <button [disabled]="programForm.invalid" class="btn btn-secondary"> {{programForm.value.index != null ? 'Update item' : 'Add Item'}}</button>
         <button class="btn btn-danger ml-2" *ngIf="programForm.value.index!=null"
                 (click)="updateMode=false;selectedIndex=null; programForm.reset()"
         >Cancel Update
         </button>
      </form>
   </div>
</div>

<div class="row d-none">
   <div class="col-md-6">
      <div class="section">All Program text</div>
      <app-editor [(ngModel)]="config.all_programs"></app-editor>
   </div>
   <div class="col-md-6">
      <div class="section">Area Of Expertise Program text</div>
      <app-editor [(ngModel)]="config.expertise"></app-editor>
   </div>
</div>

<div class="section mt-4">Pop Up Manager</div>
<div class="row pt-4 pb-4">
   <div class="col-sm-6">

      <div class="alert alert-danger" *ngIf="config.popups && config.popups.length==0">No Items</div>
      <table class="table table-sm table-striped table-bordered ">
         <tr>
            <th class="min">S.no</th>
            <th class="min">Image</th>
            <th>Link</th>
            <th>Actions</th>
         </tr>
         <tbody cdkDropList (cdkDropListDropped)="sort($event,config.popups)">
         <tr *ngFor="let item of config.popups;let idx=index" cdkDrag>
            <td class="text-center " style="vertical-align:middle;">{{idx + 1}}</td>
            <td><img [src]="photoService.exact(item.icon,70,70)" alt=""></td>
            <td>
               <a [href]="item.link" target="_blank">{{item.link}}</a>
            </td>

            <td>
               <button class="btn btn-sm btn-secondary mr-2" (click)="editPopUp(item,idx)">Edit</button>
               <button class="btn btn-sm btn-danger" (click)="this.config.popups.splice(idx,1)">Delete</button>

            </td>
         </tr>
         </tbody>
      </table>

   </div>
   <div class="col-sm-6">
      <form [formGroup]="popUpForm" (submit)="addPopItem()">
         <input type="hidden" formControlName="index">
         <div class="form-group">
            <label>Icon Upload <span>*</span></label>
            <app-photo-uploader width="any" height="any" dir="home-pop-up" formControlName="icon"></app-photo-uploader>
         </div>
         <div class="form-group">
            <label>Url/Link <span>*</span></label>
            <input type="text" class="form-control" formControlName="link">
         </div>
         <button type="submit" [disabled]="popUpForm.invalid" class="btn btn-secondary"> {{popUpForm.value.index != null ? 'Update item' : 'Add Item'}}</button>
         <button class="btn btn-danger ml-2" *ngIf="popUpForm.value.index > 0" (click)="popUpForm.reset()"> Cancel Update</button>
      </form>
   </div>
</div>

<div style="height: 100px;"></div>

<button class="btn   mt-4 btn-success btn-lg" (click)="save()" style="position: fixed;right: 10px;bottom: 10px;z-index: 100;"><i class="fa fa-save mr-2"></i>Save Settings</button>
