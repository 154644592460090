<ul class="tw-m-0">
   <li class="tw-transition-all tw-hover:bg-green tw-cursor-pointer tw-text-white  "
       [ngClass]="{'tw-bg-blue-400': !collapsed}"
   >
      <div (click)="toggleMenu()" class="px-4 py-3 tw-transition-all tw-flex tw-justify-between tw-items-center"
           [ngClass]="{'tw-bg-blue-800': !collapsed}">{{ label }}
         <i class="fa fa-angle-down tw-transition-all"
            [ngClass]="{'tw-rotate-180': !collapsed, 'tw-rotate-0': collapsed}">
         </i>
      </div>
      <ul class="sub-menu tw-m-0 tw-p-0" *ngIf="!collapsed" [@slideToggle]>
         <ng-content></ng-content>
      </ul>
   </li>
</ul>
<!--test-->
